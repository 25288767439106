import { createAction } from '@reduxjs/toolkit';

import { User } from '../auth/authActions';


// Define actions for staff
export const listStaffRequest = createAction('staff/listStaffRequest');
export const listStaffSuccess = createAction<User[]>('staff/listStaffSuccess');
export const listStaffFailure = createAction<string>('staff/listStaffFailure');

export const addStaffRequest = createAction<User>('staff/addStaffRequest');
export const addStaffSuccess = createAction<User>('staff/addStaffSuccess');
export const addStaffFailure = createAction<string>('staff/addStaffFailure');

export const removeStaffRequest = createAction<{ id: number }>('staff/removeStaffRequest');
export const removeStaffSuccess = createAction<{ id: number }>('staff/removeStaffSuccess');
export const removeStaffFailure = createAction<string>('staff/removeStaffFailure');
