import createSagaMiddleware from 'redux-saga';
import storage from 'redux-persist/lib/storage';
import { all } from 'redux-saga/effects';
import { combineReducers } from "redux";
import { createBrowserHistory } from 'history';
import { configureStore } from '@reduxjs/toolkit';
import { createReduxHistoryContext } from 'redux-first-history';
import { persistReducer, persistStore } from 'redux-persist';

import authReducer from './features/auth/authSlice';
import clipReducer from './features/clips/clipSlice';
import staffReducer from './features/staff/staffSlice';
import categoryReducer from './features/categories/categorySlice';

import { watchAuthSaga } from './features/auth/authSaga';
import { watchClipSaga } from './features/clips/clipSaga';
import { watchStaffSaga } from './features/staff/staffSaga';
import { watchCategorySaga } from './features/categories/categorySaga';

const {
    createReduxHistory,
    routerMiddleware,
    routerReducer
} = createReduxHistoryContext({ history: createBrowserHistory() });

const sagaMiddleware = createSagaMiddleware();

// Setup Redux persist
const persistAuthConfig = {
    key: 'auth',
    blacklist: ['feedback', 'error', 'loading'],
    storage,
};
const persistCategoryConfig = {
    key: 'category',
    blacklist: ['error', 'loading'],
    storage,
};
const persistClipConfig = {
    key: 'clip',
    blacklist: ['error', 'loading'],
    storage,
};

const persistedAuthReducer = persistReducer(persistAuthConfig, authReducer);
const persistedCategoryReducer = persistReducer(persistCategoryConfig, categoryReducer);
const persistedClipReducer = persistReducer(persistClipConfig, clipReducer);

export const store = configureStore({
    reducer: combineReducers({
        auth: persistedAuthReducer,
        staff: staffReducer,
        clips: persistedClipReducer,
        categories: persistedCategoryReducer,
        router: routerReducer,
    }),
    middleware: (getDefaultMiddleware: (arg0: { serializableCheck: boolean; }) => any[]) => getDefaultMiddleware({
        serializableCheck: false
    }).concat(routerMiddleware).concat(sagaMiddleware),
});
export const history = createReduxHistory(store);
export const persistor = persistStore(store);


function* rootSaga() {
    yield all([
        watchCategorySaga(),
        watchAuthSaga(),
        watchClipSaga(),
        watchStaffSaga(),
    ]);
}

sagaMiddleware.run(rootSaga);
