import axios from 'axios';

import { config } from '../config';

const api = axios.create({
    baseURL: config.apiUrl,
});

api.interceptors.request.use(
    (config) => {
        // Retrieve token from localStorage or any secure storage
        const token = localStorage.getItem('authToken');

        if (token) {
            // Attach the token to the Authorization header
            config.headers['Authorization'] = `Token ${token}`;
        }

        return config;
    },
    (error) => {
        // Handle request error
        return Promise.reject(error);
    }
);

export const secureDownload = async (url:string) => {
    try {
        const token = localStorage.getItem('authToken');
        const response = await axios({
            url: url,
            method: 'GET',
            responseType: 'blob',  // Important for file download
            headers: {
                Authorization: `Token ${token}`,  // Pass the token in the header
            },
        });

        // Create a link to trigger the download
        const link = document.createElement('a');
        link.target = '_blank'
        link.href = window.URL.createObjectURL(new Blob([response.data]));
        link.download = 'video.mp4';
        link.click();
    } catch (error) {
        console.error('Error downloading the file:', error);
    }
};

export default api;
