import { takeLatest, call, put } from 'redux-saga/effects';

import api from '../../api';
import {
    removeClipRequest,
    removeClipSuccess,
    removeClipFailure,
    listClipsRequest,
    listClipsSuccess,
    listClipsFailure,
    listFavoriteClipsRequest,
    listFavoriteClipsSuccess,
    listFavoriteClipsFailure,
    addClipRequest,
    addClipSuccess,
    addClipFailure,
    likeResponse,
    likeClipRequest,
    likeClipSuccess,
    likeClipFailure,
    viewResponse,
    viewClipRequest,
    viewClipSuccess,
    viewClipFailure,
    unlikeResponse,
    unlikeClipRequest,
    unlikeClipSuccess,
    unlikeClipFailure,
    Clip
} from './clipActions';

// API calls
const addClipApi = async (data: any) => {
    const response = await api.post('/api/clips/', data);
    return response.data;
};

const removeClipApi = async (clipId: number): Promise<void> => {
    await api.delete(`/api/clips/${clipId}`);
};

const viewClipApi = async (clipId: number): Promise<viewResponse> => {
    const response = await api.post(`/api/clips/${clipId}/view/`);
    return response.data;
};

const likeClipApi = async (clipId: number): Promise<void> => {
    await api.post(`/api/clips/${clipId}/like/`);
};

const unlikeClipApi = async (clipId: number): Promise<void> => {
    await api.post(`/api/clips/${clipId}/unlike/`);
};

const listClipsApi = async (order_by: string): Promise<Clip[]> => {
    const response = await api.get(`/api/clips/?order_by=${order_by}`);
    return response.data;
};

const listFavoriteClipsApi = async (order_by: string): Promise<Clip[]> => {
    const response = await api.get(`/api/clips/favorites/?order_by=${order_by}`);
    return response.data;
};

// Sagas
function* handleAddClip(action: ReturnType<typeof addClipRequest>) {
    try {
        const data: Clip = yield call(addClipApi, action.payload);
        yield put(addClipSuccess(data));
    } catch (error: any) {
        let errmsg = error.response?.data?.non_field_errors || 'Network error';
        yield put(addClipFailure(errmsg)); // Dispatch failure action
    }
}

function* handleRemoveClip(action: ReturnType<typeof removeClipRequest>) {
    try {
        yield call(removeClipApi, action.payload.id);
        yield put(removeClipSuccess(action.payload));
    } catch (error: any) {
        let errmsg = error.response?.data?.non_field_errors || 'Network error';
        yield put(removeClipFailure(errmsg)); // Dispatch failure action
    }
}

function* handleListClips(action: ReturnType<typeof listClipsRequest>) {
    try {
        const data: Clip[] = yield call(listClipsApi, action.payload.order_by);
        yield put(listClipsSuccess(data));
    } catch (error: any) {
        let errmsg = error.response?.data?.non_field_errors || 'Network error';
        yield put(listClipsFailure(errmsg)); // Dispatch failure action
    }
}

function* handleListClipsFavorite(action: ReturnType<typeof listFavoriteClipsRequest>) {
    try {
        const data: Clip[] = yield call(listFavoriteClipsApi, action.payload.order_by);
        yield put(listFavoriteClipsSuccess(data));
    } catch (error: any) {
        let errmsg = error.response?.data?.non_field_errors || 'Network error';
        yield put(listFavoriteClipsFailure(errmsg)); // Dispatch failure action
    }
}

function* handleViewClip(action: ReturnType<typeof viewClipRequest>) {
    try {
        const data: viewResponse = yield call(viewClipApi, action.payload.id);
        if (action.payload?.cb) {
            action.payload?.cb(data.result)
        }
        yield put(viewClipSuccess(data));
    } catch (error: any) {
        let errmsg = error.response?.data?.non_field_errors || 'Network error';
        yield put(viewClipFailure(errmsg)); // Dispatch failure action
    }
}

function* handleLikeClip(action: ReturnType<typeof likeClipRequest>) {
    try {
        const data: likeResponse = yield call(likeClipApi, action.payload.id);
        yield put(likeClipSuccess(data));
    } catch (error: any) {
        let errmsg = error.response?.data?.non_field_errors || 'Network error';
        yield put(likeClipFailure(errmsg)); // Dispatch failure action
    }
}

function* handleUnlikeClip(action: ReturnType<typeof unlikeClipRequest>) {
    try {
        const data: unlikeResponse = yield call(unlikeClipApi, action.payload.id);
        yield put(unlikeClipSuccess(data));
    } catch (error: any) {
        let errmsg = error.response?.data?.non_field_errors || 'Network error';
        yield put(unlikeClipFailure(errmsg)); // Dispatch failure action
    }
}

// Watcher sagas
export function* watchClipSaga() {
    yield takeLatest(addClipRequest.type, handleAddClip);       // Listen for add clip request
    yield takeLatest(removeClipRequest.type, handleRemoveClip); // Listen for remove clip request
    yield takeLatest(listClipsRequest.type, handleListClips); // Listen for list clips request
    yield takeLatest(listFavoriteClipsRequest.type, handleListClipsFavorite); // Listen for list clips request
    yield takeLatest(viewClipRequest.type, handleViewClip);
    yield takeLatest(likeClipRequest.type, handleLikeClip);
    yield takeLatest(unlikeClipRequest.type, handleUnlikeClip);
}
