import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import Loader from '../components/Loader';
import AuthRedirect from '../components/AuthRedirect';
import ProtectedPage from '../components/ProtectedPage';

const Dashboard = React.lazy(() => import("../pages/Dashboard"));
//const Landing = React.lazy(() => import("../pages/Landing"));
const Login = React.lazy(() => import("../pages/Login"));
const Signup = React.lazy(() => import("../pages/Signup"));
const PasswordReset = React.lazy(() => import("../pages/PasswordReset"));


const ProjectRoutes = () => {
    const auth_routes = [
        { path: '/login', component: Login },
        { path: '/signup', component: Signup },
        { path: '/password-reset', component: PasswordReset },
    ];

    const securedRoutes = [
        { path: '/dashboard/:tab/*', component: Dashboard },
        { path: '/dashboard', component: Dashboard },
    ];

    function getLoader() {
        return (
            <div className="flex items-center justify-center min-h-screen p-5 bg-gray-100 min-w-screen">
                <Loader />
            </div>
        )
    }

    return (
        <React.Suspense fallback={getLoader()}>
            <Routes>
                {/*
                <Route path="/" element={<Navigate to="/" replace />} />
                  */}
                {auth_routes.map((route, index) => {
                    const Component = route.component;
                    return <Route key={index} path={route.path} element={<AuthRedirect><Component /></AuthRedirect>} />
                })}
                {securedRoutes.map((route, index) => {
                    const Component = route.component;
                    return <Route key={'secure_'+index} path={route.path} element={<ProtectedPage><Component /></ProtectedPage>} />
                })}
                {/* Catch-all route for non-existent pages */}
                <Route path="*" element={<Navigate to="/login" replace />} />
            </Routes>
        </React.Suspense>
    );
};

export default ProjectRoutes;
