import { takeLatest, call, put } from 'redux-saga/effects';

import api from '../../api';
import {
    removeCategoryRequest,
    removeCategorySuccess,
    removeCategoryFailure,
    listCategoriesRequest,
    listCategoriesSuccess,
    listCategoriesFailure,
    addCategoryRequest,
    addCategorySuccess,
    addCategoryFailure,
    Category
} from './categoryActions';

// API calls
const addCategoryApi = async (category: Category): Promise<Category> => {
    const response = await api.post('/api/categories/', category); 
    return response.data;
};

const removeCategoryApi = async (categoryId: number): Promise<void> => {
    await api.delete(`/api/categories/${categoryId}`);
};

const listCategoriesApi = async (): Promise<Category[]> => {
    const response = await api.get('/api/categories/');
    return response.data;
};

// Sagas
function* handleAddCategory(action: ReturnType<typeof addCategoryRequest>) {
    try {
        const data: Category = yield call(addCategoryApi, action.payload);
        yield put(addCategorySuccess(data));
    } catch (error: any) {
        yield put(addCategoryFailure(error.response?.data || 'Failed to add category'));
    }
}

function* handleRemoveCategory(action: ReturnType<typeof removeCategoryRequest>) {
    try {
        yield call(removeCategoryApi, action.payload.id);
        yield put(removeCategorySuccess(action.payload));
    } catch (error: any) {
        yield put(removeCategoryFailure(error.response?.data || 'Failed to remove category'));
    }
}

function* handleListCategories() {
    try {
        const data: Category[] = yield call(listCategoriesApi);
        yield put(listCategoriesSuccess(data));
    } catch (error: any) {
        yield put(listCategoriesFailure(error.response?.data || 'Failed to list categories'));
    }
}

// Watcher sagas
export function* watchCategorySaga() {
    yield takeLatest(addCategoryRequest.type, handleAddCategory);       // Listen for add category request
    yield takeLatest(removeCategoryRequest.type, handleRemoveCategory); // Listen for remove category request
    yield takeLatest(listCategoriesRequest.type, handleListCategories); // Listen for list categories request
}
