import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { resetErrorRequest as resetAuth } from '../redux/features/auth/authActions';
import { resetErrorRequest as resetCategry } from '../redux/features/categories/categoryActions';
import { resetErrorRequest as resetClips } from '../redux/features/clips/clipActions';
import { useLocation } from 'react-router-dom';


const ErrorReset = () => {
    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        // Reset error whenever the route changes
        dispatch(resetAuth());
        dispatch(resetCategry());
        dispatch(resetClips());
    }, [dispatch, location]);

    return null; // This component doesn't render anything
};

export default ErrorReset;
