import React, { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';


interface ProtectedRouteProps {
  children: ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
    const user = useSelector((state: any) => state.auth.auth); // Access auth state
    //const token = localStorage.getItem('authToken');

    if (!user) {
        // If not authenticated, redirect to login
        return <Navigate to="/" replace />;
    }

    // If authenticated, render the child component
    return children;
};

export default ProtectedRoute;
