import { takeLatest, call, put } from 'redux-saga/effects';

import api from '../../api';
import { User } from '../auth/authActions';
import {
    removeStaffRequest,
    removeStaffSuccess,
    removeStaffFailure,
    listStaffRequest,
    listStaffSuccess,
    listStaffFailure,
    addStaffRequest,
    addStaffSuccess,
    addStaffFailure,
} from './staffActions';

// API calls
const addStaffApi = async (staff: User): Promise<User> => {
    const response = await api.post('/api/staff/', staff); 
    return response.data;
};

const removeStaffApi = async (staffId: number): Promise<void> => {
    await api.delete(`/api/staff/${staffId}`);
};

const listStaffApi = async (): Promise<User[]> => {
    const response = await api.get('/api/staff/');
    return response.data;
};

// Sagas
function* handleAddStaff(action: ReturnType<typeof addStaffRequest>) {
    try {
        const data: User = yield call(addStaffApi, action.payload);
        yield put(addStaffSuccess(data));
    } catch (error: any) {
        let errmsg = error.response?.data?.non_field_errors || 'Network error';
        yield put(addStaffFailure(errmsg));
    }
}

function* handleRemoveStaff(action: ReturnType<typeof removeStaffRequest>) {
    try {
        yield call(removeStaffApi, action.payload.id);
        yield put(removeStaffSuccess(action.payload));
    } catch (error: any) {
        let errmsg = error.response?.data?.non_field_errors || 'Network error';
        yield put(removeStaffFailure(errmsg));
    }
}

function* handleListStaff() {
    try {
        const data: User[] = yield call(listStaffApi);
        yield put(listStaffSuccess(data));
    } catch (error: any) {
        let errmsg = error.response?.data?.non_field_errors || 'Network error';
        yield put(listStaffFailure(errmsg));
    }
}

// Watcher sagas
export function* watchStaffSaga() {
    yield takeLatest(addStaffRequest.type, handleAddStaff);       // Listen for add staff request
    yield takeLatest(removeStaffRequest.type, handleRemoveStaff); // Listen for remove staff request
    yield takeLatest(listStaffRequest.type, handleListStaff); // Listen for list staff request
}
