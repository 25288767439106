import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { HistoryRouter as Router } from 'redux-first-history/rr6';

import App from './App.tsx';
import ProjectRoutes from './routes'
import { store, history, persistor } from './redux';
import { initSentry, config } from './config';

import './styles/index.css';
import './styles/tailwind.css';

initSentry();

const Main = () => {
    return (
        <Provider store={store}>
            <PersistGate loading={<div>Loading...</div>} persistor={persistor}>
                <Router history={history}>
                    <ProjectRoutes />
                    <App />
                </Router>
            </PersistGate>
        </Provider>
    );
};

ReactDOM.createRoot(document.getElementById('root')!).render(
    <GoogleOAuthProvider clientId={config.google_auth_client_id}>
        <React.StrictMode>
            <Main />
        </React.StrictMode>
    </GoogleOAuthProvider>,
)
