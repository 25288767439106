import * as Sentry from "@sentry/react";

const configs = {
    development: {
        apiUrl: 'http://arch.local:8602',
        google_auth_client_id: '499004089622-kiuo5hbqlv2527qnusoha0b4n5s88ldb.apps.googleusercontent.com',
        google_auth_redirect_url: ''
,
    },
    production: {
        apiUrl: 'https://reclipper-api.onpy.mx',
        google_auth_client_id: '499004089622-kiuo5hbqlv2527qnusoha0b4n5s88ldb.apps.googleusercontent.com',
        google_auth_redirect_url: ''
    },
};

type Environment = 'development' | 'production';

const currentEnv: Environment = (process.env.NODE_ENV as Environment) || 'development';

export const initSentry = () => {
    if (currentEnv === 'production') {
        Sentry.init({
            dsn: "https://b32eddaa12f4ffae35fd8af908fd3979@o4508424058765312.ingest.us.sentry.io/4508424096448512",
            integrations: [
                Sentry.browserTracingIntegration(),
                Sentry.replayIntegration(),
            ],
            // Tracing
            tracesSampleRate: 1.0, //  Capture 100% of the transactions
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: [/^https:\/\/reclipper-api\.onpy\.mx\/api/],
            // Session Replay
            replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        });
    }
}

export const config = configs[currentEnv];
