import { takeLatest, call, put } from 'redux-saga/effects';
import { push } from 'redux-first-history';

import api from '../../api';
import {
    User,
    loginRequest,
    loginSuccess,
    loginFailure,
    deleteRequest,
    deleteSuccess,
    deleteFailure,
    loginGoogleRequest,
    signupRequest,
    signupSuccess,
    signupFailure,
    checkEmailRequest,
    checkEmailSuccess,
    checkEmailFailure,
    resetPasswordRequest,
    resetPasswordSuccess,
    resetPasswordFailure,
    logoutRequest,
    LoginResponse,
    SignupResponse,
    ResetPasswordResponse,
    EmailStatusResponse,
    SystemStatsResultsResponse,
    systemStatsRequest,
    systemStatsSuccess,
    systemStatsFailure,
    ConstantsResponse,
    constantsUpdateRequest,
    constantsRequest,
    constantsSuccess,
    constantsFailure,
    profileRequest,
    profileSuccess,
    profileFailure,
    FeedbackResponse,
    feedbackRequest,
    feedbackSuccess,
    feedbackFailure,
} from './authActions';


// API call
const loginApi = async (credentials: any) => {
    const response = await api.post('/api/auth/login/', credentials);
    return response.data;
};

const deleteApi = async () => {
    const response = await api.post('/api/auth/delete/');
    return response.data;
};

const loginGoogleApi = async (credentials: any) => {
    const response = await api.post('/api/auth/signup_google/', credentials);
    return response.data;
};

const signupApi = async (credentials: any) => {
    const response = await api.post('/api/auth/signup/', credentials);
    return response.data;
};

const checkEmailApi = async (credentials: any) => {
    const response = await api.post('/api/auth/check_email/', credentials);
    return response.data;
};

const resetPasswordApi = async (credentials: any) => {
    const response = await api.post('/api/auth/reset-password/', credentials);
    return response.data;
};

const systemStatsApi = async () => {
    const response = await api.get('/api/stats/');
    return response.data;
};

const constantsApi = async () => {
    const response = await api.get('/api/constants/');
    return response.data;
};

const constantsUpdateApi = async (data: Record<string, string|number>) => {
    const response = await api.post('/api/constants/', data);
    return response.data;
};

const profileApi = async (): Promise<User> => {
    const response = await api.get('/api/profile/');
    return response.data;
};

const feedbackApi = async (data: any) => {
    const response = await api.post('/api/feedback/', data);
    return response.data;
};

// Saga for login
function* handleLogin(action: ReturnType<typeof loginRequest>) {
    try {
        const data: LoginResponse = yield call(loginApi, action.payload);

        yield put(loginSuccess(data)); // Dispatch success action

        // Navigate to dashboard after login
        if (data?.user?.is_staff) {
            yield put(push('/dashboard/admin/'));
        } else {
            yield put(push('/dashboard'));
        }
    } catch (error: any) {
        let errmsg = error.response?.data?.non_field_errors || 'Network error';
        yield put(loginFailure(errmsg)); // Dispatch failure action
    }
}
//
// Saga for google login
function* handleLoginGoogle(action: ReturnType<typeof loginRequest>) {
    try {
        const data: LoginResponse = yield call(loginGoogleApi, action.payload);

        yield put(loginSuccess(data)); // Dispatch success action
        // Navigate to dashboard after login
        if (data?.user?.is_staff) {
            yield put(push('/dashboard/admin/'));
        } else {
            yield put(push('/dashboard'));
        }
    } catch (error: any) {
        let errmsg = error.response?.data?.non_field_errors || 'Network error';
        yield put(loginFailure(errmsg)); // Dispatch failure action
    }
}

// Saga for signupu
function* handleSignup(action: ReturnType<typeof signupRequest>) {
    try {
        const data: SignupResponse = yield call(signupApi, action.payload);

        if (data.result == "success") {
            yield put(signupSuccess(data)); // Dispatch success action
            // Navigate to dashboard after login
            if (data?.user?.is_staff) {
                yield put(push('/dashboard/admin/'));
            } else {
                yield put(push('/dashboard'));
            }
        } else {
            yield put(signupFailure(data?.message)); // Dispatch failure action
        }
    } catch (error: any) {
        let errmsg = error.response?.data?.non_field_errors || 'Network error';
        yield put(signupFailure(errmsg)); // Dispatch failure action
    }
}

// Saga for checkEmail
function* handleCheckEmail(action: ReturnType<typeof checkEmailRequest>) {
    try {
        const data: EmailStatusResponse = yield call(checkEmailApi, action.payload);

        yield put(checkEmailSuccess(data)); // Dispatch success action
    } catch (error: any) {
        let errmsg = error.response?.data?.non_field_errors || 'Network error';
        yield put(checkEmailFailure(errmsg)); // Dispatch failure action
    }
}

// Saga for resetPassword
function* handleResetPassword(action: ReturnType<typeof resetPasswordRequest>) {
    try {
        const data: ResetPasswordResponse = yield call(resetPasswordApi, action.payload);

        if (data?.result == "success") {
            yield put(resetPasswordSuccess(true)); // Dispatch success action
        }
    } catch (error: any) {
        let errmsg = error.response?.data?.non_field_errors || 'Network error';
        yield put(resetPasswordFailure(errmsg)); // Dispatch failure action
    }
}

// Saga for logout
function* handleLogout() {
    try {
        yield put(push('/login'));     // Navigate to login after logout
    } catch (error: any) {
        yield put(loginFailure(error.response?.data)); // Dispatch failure action
    }
}

// Saga for System stats
function* handleSystemStats() {
    try {
        const data: SystemStatsResultsResponse = yield call(systemStatsApi);

        yield put(systemStatsSuccess(data.result)); // Dispatch success action
    } catch (error: any) {
        yield put(systemStatsFailure(error.response?.data)); // Dispatch failure action
    }
}

// Saga for System stats
function* handleConstants() {
    try {
        const data: ConstantsResponse = yield call(constantsApi);

        yield put(constantsSuccess(data.result)); // Dispatch success action
    } catch (error: any) {
        yield put(constantsFailure(error.response?.data)); // Dispatch failure action
    }
}

function* handleConstantsUpdate(action: ReturnType<typeof constantsUpdateRequest>) {
    try {
        const data: ConstantsResponse = yield call(constantsUpdateApi, action.payload);

        yield put(constantsSuccess(data.result)); // Dispatch success action
    } catch (error: any) {
        yield put(constantsFailure(error.response?.data)); // Dispatch failure action
    }
}

// Saga for System stats
function* handleProfile() {
    try {
        const data: User = yield call(profileApi);

        yield put(profileSuccess(data)); // Dispatch success action
    } catch (error: any) {
        yield put(profileFailure(error.response?.data)); // Dispatch failure action
    }
}

// Saga for Feedback
function* handleFeedback(action: ReturnType<typeof feedbackRequest>) {
    try {
        const data: FeedbackResponse = yield call(feedbackApi, action.payload);

        yield put(feedbackSuccess(data.result)); // Dispatch success action
    } catch (error: any) {
        let errmsg = error.response?.data?.non_field_errors || 'Network error';
        yield put(feedbackFailure(errmsg)); // Dispatch failure action
    }
}

// Saga for Delete Accounut
function* handleDelete() {
    try {
        yield call(deleteApi);
        yield put(deleteSuccess()); // Dispatch success action
        yield put(push('/login')); // Navigate to dashboard after login
    } catch (error: any) {
        let errmsg = error.response?.data?.non_field_errors || 'Network error';
        yield put(deleteFailure(errmsg)); // Dispatch failure action
    }
}

// Watcher Saga
export function* watchAuthSaga() {
    yield takeLatest(loginRequest.type, handleLogin);   // Listen for login request
    yield takeLatest(loginGoogleRequest.type, handleLoginGoogle);   // Listen for login request
    yield takeLatest(signupRequest.type, handleSignup);   // Listen for login request
    yield takeLatest(checkEmailRequest.type, handleCheckEmail);   // Listen for login request
    yield takeLatest(resetPasswordRequest.type, handleResetPassword);   // Listen for login request
    yield takeLatest(logoutRequest.type, handleLogout); // Listen for logout request
    yield takeLatest(systemStatsRequest.type, handleSystemStats); // Listen for logout request
    yield takeLatest(constantsRequest.type, handleConstants); // Listen for logout request
    yield takeLatest(constantsUpdateRequest.type, handleConstantsUpdate); // Listen for logout request
    yield takeLatest(profileRequest.type, handleProfile); // Listen for logout request
    yield takeLatest(feedbackRequest.type, handleFeedback); // Listen for logout request
    yield takeLatest(deleteRequest.type, handleDelete); // Listen for delete request
}
