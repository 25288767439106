import { createAction } from '@reduxjs/toolkit';

// Define types for Category
export interface Category {
    title: string;
    id?: number;
    videos?: number;
    views?: number;
    likes?: number;
    downloads?: number;
}

// Define actions for categories
export const removeCategoryRequest = createAction<{ id: number }>('categories/removeCategoryRequest');
export const removeCategorySuccess = createAction<{ id: number }>('categories/removeCategorySuccess');
export const removeCategoryFailure = createAction<string>('categories/removeCategoryFailure');

export const addCategoryRequest = createAction<Category>('categories/addCategoryRequest');
export const addCategorySuccess = createAction<Category>('categories/addCategorySuccess');
export const addCategoryFailure = createAction<string>('categories/addCategoryFailure');

export const listCategoriesRequest = createAction('categories/listCategoriesRequest');
export const listCategoriesSuccess = createAction<Category[]>('categories/listCategoriesSuccess');
export const listCategoriesFailure = createAction<string>('categories/listCategoriesFailure');

export const resetErrorRequest = createAction('category/resetErrorRequest');
