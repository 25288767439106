import { useEffect } from 'react';
import { Helmet } from "react-helmet";
import { useDispatch } from 'react-redux';

import './App.css'
import ErrorReset from './components/ErrorReset'
import {
    constantsRequest,
} from './redux/features/auth/authActions';


function App() {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(constantsRequest());
    }, [dispatch]);

    return (
        <>
            <Helmet>
                <title>Reclipper - Your next Viral video is just a clip away</title>
            </Helmet>
            <ErrorReset />
        </>
    )
}

export default App;
