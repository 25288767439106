import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { User } from '../auth/authActions';
import {
    removeStaffRequest,
    removeStaffSuccess,
    removeStaffFailure,
    listStaffRequest,
    listStaffSuccess,
    listStaffFailure,
    addStaffRequest,
    addStaffSuccess,
    addStaffFailure,
} from './staffActions';

interface StaffState {
    staff: User[];
    loading: boolean;
    error: string | null;
}

const initialState: StaffState = {
    staff: [],
    loading: false,
    error: null,
};

const staffReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(removeStaffRequest, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(addStaffRequest, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(listStaffRequest, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(addStaffSuccess, (state, action) => {
            state.staff.push(action.payload); // Add staff to the list
            state.loading = false;
        })
        .addCase(removeStaffSuccess, (state, action: PayloadAction<{ id: number }>) => {
            state.staff = state.staff.filter(staff => staff.id !== action.payload.id);
            state.loading = false;
        })
        .addCase(listStaffSuccess, (state, action) => {
            state.staff = action.payload; // Set stafs from the server
            state.loading = false;
        })
        .addCase(addStaffFailure, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })
        .addCase(removeStaffFailure, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })
        .addCase(listStaffFailure, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
});

export default staffReducer;
